<template>
  <div>
    <div class="newslist">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">最新资讯</div>
          <div class="tit2"></div>
        </div>
      </div>
      <ul>
        <li v-for="(item, index) in newslist" :key="index" @click="routerlink(item.articleId,index)">
          <div class="newslist_info">
            <div class="infoimg">
              <img :src="item.articleCover" alt="" />
            </div>
            <div class="infocon">
              <div class="contop">
                <div class="toptit" v-html="item.articleTitle"></div>
                <div class="toptxt" v-html="item.articleContent">

                </div>
              </div>

              <div class="conbot">
                <div class="infoleft">
                  <div class="newssee">
                    <img src="@/assets/images/01首页/浏览.png" alt="" />
                    <p>132</p>
                  </div>
                  <div class="newsnice">
                    <img src="@/assets/images/01首页/点赞.png" alt="" />
                    <p>86</p>
                  </div>
                </div>
                <div class="inforight">
                  <div class="newstime">
                    <img src="@/assets/images/01首页/发布日期.png" alt="" />
                    <p>2019-04-16</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import newsimg1 from "@/assets/images/03新闻动态/方彤：现实与虚拟场景“全”交互体验.png";
import newsimg2 from "@/assets/images/03新闻动态/梦宇3D 亮相国家“十二五”科技创新成就展.png";
import newsimg3 from "@/assets/images/03新闻动态/中影梦宇实时动作捕捉中心.png";
import newsimg4 from "@/assets/images/03新闻动态/总经理方彤到访合肥研究院先进制造所.png";
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      newslist: [],
    };
  },
  created() {
    this.getcompany();
  },
  methods: {
    routerlink(id, index) {
      // console.log(id);
      // console.log(index);
      this.$router.push({
        path: "/m_news/newsdetail",
        query: { id: id, index: index },
      });
    },
    async getcompany() {
      let params = {
        barId: 3,
      };
      const data = await getbannerdetail(params);

      this.newslist = data.data.children[0].portalArticleList;
      for (let i = 0; i < this.newslist.length; i++) {
        let text = "http://36.152.65.166:10008";
        this.newslist[i].articleCover = text + this.newslist[i].articleCover;
      }
      // console.log(this.newslist);
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.product_title {
  width: 7.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    .tit1 {
      font-size: 0.3rem;
      font-weight: bold;
      margin-bottom: 0.13rem;
    }
    .tit2 {
      width: 0.6rem;
      height: 0.04rem;
      background-color: #0088ea;
    }
  }
}
.newslist {
  height: 11.5rem;
  ul {
    width: 95%;
    padding: 0;
    margin: 0 auto;
    margin-top: 0.6rem;
    li {
      cursor: pointer;
      margin-bottom: 0.3rem;
      .newslist_info {
        display: flex;
        .infoimg {
          width: 40%;
          height: auto;
          box-sizing: border-box;
          border: 1px solid #c1c3cb;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid #ffffff;
          }
        }
        .infocon {
          width: 4.5rem;
          height: auto;
          background-color: #e8eaf2;

          .contop {
            padding: 5px 20px;
            height: 1.7rem;
            display: flex;
            flex-direction: column;

            justify-content: space-evenly;

            .toptit {
              font-size: 0.26rem;
              font-weight: bold;
            }
            .toptxt {
              // border: solid;
              overflow: hidden;
              ::v-deep p{
                text-overflow: ellipsis;
                   overflow: hidden;
                  white-space: nowrap;
              }
              // overflow: hidden;
              // display: -webkit-box;
              // -webkit-box-orient: vertical;
              // -webkit-line-clamp: ; /* 控制显示的行数 */
       
              font-size: 0.18rem;

            }
          }
          .conbot {
            display: flex;
            padding: 0.1rem;
            justify-content: space-between;
            height: auto;
            // width: 100%;
            border-top: 0.02rem solid #c1c3cb;
            img {
              margin-right: 0.11rem;
            }
            .infoleft {
              width: auto;
              display: flex;
              align-items: center;
              // justify-content: space-around;
              div {
                display: flex;
                align-items: center;
                img{
                  width: 20px;
                }
                p{
                  font-size: 0.22rem;
                }
              }
              .newsnice {
                margin-left: 0.2rem;
              }
            }
            .inforight {
              width: auto;
              display: flex;
              align-items: center;
              div {
                display: flex;
                align-items: center;
                img{
                  width: 20px;
                }
                p {
                  font-size: 0.22rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>